import { useCallback, useMemo, useState } from 'react';

export const usePagination = (totalResults: number, pageSize: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = useMemo(() => Math.ceil(totalResults / pageSize), [totalResults, pageSize]);

  const setNextPage = useCallback(() => {
    if(currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }, [currentPage, totalPages])

  const setPreviousPage = useCallback(() => {
    if(currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }, [currentPage])

  const nextEnabled = useMemo(() => currentPage < totalPages, [currentPage, totalPages]);
  const previousEnabled = useMemo(() => currentPage > 1, [currentPage]);

  return {
    currentPage,
    totalPages,
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
  }
}
