import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

export const TeamsContext = React.createContext<{
  teamsClient: typeof microsoftTeams | undefined;
  teamsContext: microsoftTeams.app.Context | undefined;
  authCode: string | undefined;
  renewAuthCode: (() => void) | undefined;
}>({
  teamsClient: undefined,
  teamsContext: undefined,
  authCode: undefined,
  renewAuthCode: undefined,
});

export const TeamsContextProvider = (props: { children: ReactNode }) => {
  const { children } = props;

  const [teamsClient] = useState<typeof microsoftTeams>(microsoftTeams);
  const [teamsContext, setTeamsContext] = useState<
    microsoftTeams.app.Context | undefined
  >();
  const [authCode, setAuthCode] = useState<string | undefined>();

  const retrieveContext = useCallback(() => {
    microsoftTeams.app.getContext().then((context) => {
      setTeamsContext(context);
    });
  }, []);

  const retrieveAuthCode = useCallback(() => {
    console.log('Retrieving a new authentication token');
    microsoftTeams.authentication
      .getAuthToken()
      .then((authCode) => {
        setAuthCode(authCode);
      })
      .catch((error) => {
        console.error('Failed to get auth: ', error);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    retrieveContext();
    retrieveAuthCode();
  }, [retrieveContext, retrieveAuthCode]);

  useEffect(() => {
    if (authCode) {
      const expiresIn = getExpiration(authCode) - Date.now();
      console.log(`Authentication token will expire in ${expiresIn}ms`);
      const timeout = setTimeout(() => {

        retrieveAuthCode();
      }, Math.min(1000 * 60, expiresIn));
      return () => clearTimeout(timeout);
    }
  }, [authCode, retrieveAuthCode]);

  return (
    <TeamsContext.Provider
      value={{
        teamsClient,
        teamsContext,
        authCode,
        renewAuthCode: retrieveAuthCode,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

const getExpiration = (authCode: string) => {
  const payloadBase64 = authCode.split('.')[1];
  const base64 = payloadBase64.replace(/-/g, '+').replace(/_/g, '/');
  const decodedJwt = JSON.parse(window.atob(base64));
  return (decodedJwt['exp'] as number) * 1000;
};

export const useTeamsContext = () => {
  return useContext(TeamsContext);
};
