import { z } from 'zod';
import { PlatformEnum } from './platform';
import { MeetingReportSchema } from './summarize-meeting-result';
import { RosterSchema } from './roster';
import { StepsStatusSchema } from './status';

export const RecordingDTOSchema = z.object({
  id: z.string(),
  platform: PlatformEnum,
  meetingId: z.string(),
  meetingUrl: z.string(),
  tenantId: z.string(),
  name: z.string().optional(),
  startTime: z.number().optional(),
  endTime: z.number().optional(),
  roster: RosterSchema,
  report: MeetingReportSchema.optional(),
  status: StepsStatusSchema
});

export type RecordingDTO = z.infer<typeof RecordingDTOSchema>;

