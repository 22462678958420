import { z } from 'zod';
import { RosterSchema } from './roster';
import { TimelapseSchema } from './timelapse';

export const RecordMeetingResultSchema = z.object({
  recordMediaPath: z.string(),
  roster: RosterSchema,
  timelapse: TimelapseSchema,
  subject: z.string().optional(),
  startTime: z.number(),
  endTime: z.number()
});

export type RecordMeetingResult = z.infer<typeof RecordMeetingResultSchema>;

