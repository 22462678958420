import { boolean, z } from 'zod';

export const ParticipantSchema = z.object({
  name: z.string(),
  id: z.string(),
  organizer: boolean()
});

export type Participant = z.infer<typeof ParticipantSchema>;

export const RosterSchema = z.array(ParticipantSchema);

export type Roster = z.infer<typeof RosterSchema>;
