import { z } from 'zod';
import { ParticipantSchema } from './roster';


export const ParticipantChangeSchema = ParticipantSchema.extend({
  speaking: z.boolean(),
  muted: z.boolean()
});

export type ParticipantChange = z.infer<typeof ParticipantChangeSchema>;

export const RosterChangeSchema = z.object({
  roster: z.array(ParticipantChangeSchema),
  time: z.number()
});

export type RosterChange = z.infer<typeof RosterChangeSchema>;

export const TimelapseSchema = z.array(RosterChangeSchema);

export type Timelapse = z.infer<typeof TimelapseSchema>;
