import { Breadcrumb, BreadcrumbButton, BreadcrumbDivider, BreadcrumbItem } from '@fluentui/react-components';
import React from 'react';

export const Navbar = (props: {
  path: {path?: string, name: string}[]
}) => {
  const { path } = props;
  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbButton href={'/'}>Dashboard</BreadcrumbButton>
      </BreadcrumbItem>

      {path.map((p, index) => (
        <React.Fragment key={index}>
          <BreadcrumbDivider />
          <BreadcrumbItem>
            {!p.path ? (
              p.name
            ): (
              <BreadcrumbButton href={p.path}>{p.name}</BreadcrumbButton>
            )}
          </BreadcrumbItem>
        </React.Fragment>
      ))}
    </Breadcrumb>
  )
}
