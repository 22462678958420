import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Card,
  tokens,
} from '@fluentui/react-components';
import { List, ListItem } from '@fluentui/react-list-preview';
import { MeetingReport } from '@axys-notes/common';

export const MeetingNotes = (props: { report: MeetingReport }) => {
  const { report } = props;
  return (
    <Card
      style={{
        backgroundColor: tokens.colorSubtleBackgroundHover,
        flex: 1,
      }}
    >
      <h2 style={{ fontWeight: 'bold', paddingLeft: 15 }}>
        Compte-Rendu de la réunion
      </h2>
      <div style={{ padding: '0 10px' }}>
        <List>
          <ListItem>
            <h3>Synthèse</h3>
            <div>{report.summary}</div>
          </ListItem>
          <ListItem>
            <h3>Sujets abordés</h3>
            <Accordion
              collapsible
              multiple
              defaultOpenItems={[...report.topics.keys()].map(
                (i) => `topic-${i}`
              )}
            >
              {report.topics.map((topic, indexTopic) => (
                <AccordionItem
                  key={indexTopic}
                  value={`topic-${indexTopic}`}
                  style={{ margin: '20px 0px' }}
                >
                  <AccordionHeader
                    style={{ color: tokens.colorBrandForeground1 }}
                  >
                    {topic.topic}
                  </AccordionHeader>
                  <AccordionPanel style={{ margin: '0 30px' }}>
                    <div>
                      <div>{topic.summary}</div>
                      <Accordion
                        collapsible
                        multiple
                        defaultOpenItems={[
                          `topic-${indexTopic}-keyPoints`,
                          `topic-${indexTopic}-actions`,
                        ]}
                      >
                        {topic.actions.length > 0 && (
                          <AccordionItem
                            value={`topic-${indexTopic}-keyPoints`}
                            style={{ margin: '10px 0px' }}
                          >
                            <AccordionHeader
                              style={{ color: tokens.colorBrandForeground2 }}
                            >
                              Points clés
                            </AccordionHeader>
                            <AccordionPanel style={{ margin: '0 10px' }}>
                              <Card>
                                <List style={{ marginTop: 5 }}>
                                  {topic.keyPoints.map(
                                    (keyPoint, indexKeypoint) => (
                                      <ListItem
                                        key={`${indexTopic}-keypoint-${indexKeypoint}`}
                                        style={{
                                          listStyle: 'number',
                                          marginLeft: 30,
                                          marginBottom: 5,
                                        }}
                                      >
                                        <h5 style={{ margin: 0, fontSize: 14 }}>
                                          {keyPoint.keyPoint}
                                        </h5>
                                        <div>{keyPoint.summary}</div>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </Card>
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                        {topic.actions.length > 0 && (
                          <AccordionItem
                            value={`topic-${indexTopic}-actions`}
                            style={{ margin: '10px 0px' }}
                          >
                            <AccordionHeader
                              style={{ color: tokens.colorBrandForeground2 }}
                            >
                              Actions
                            </AccordionHeader>
                            <AccordionPanel style={{ margin: '0 10px' }}>
                              <Card>
                                <List>
                                  {topic.actions.map((action, actionIndex) => (
                                    <ListItem
                                      key={`${indexTopic}-action-${actionIndex}`}
                                      style={{
                                        listStyleType: 'lower-alpha',
                                        marginLeft: 30,
                                        marginBottom: 5,
                                      }}
                                    >
                                      <h5 style={{ margin: 0, fontSize: 14 }}>
                                        {action.action}
                                      </h5>
                                      <div>{action.summary}</div>
                                    </ListItem>
                                  ))}
                                </List>
                              </Card>
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                      </Accordion>
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </ListItem>
        </List>
      </div>
    </Card>
  );
};
