import {
  Card,
  CompoundButton,
  Input,
  Label,
  tokens,
  useId,
} from '@fluentui/react-components';
import React, { CSSProperties, useCallback, useState } from 'react';
import { useRecordMeetingMutation } from '../../queries/recording';
import { BotAddRegular } from '@fluentui/react-icons';

export const NewRecordingPanel = (props: {
  style?: CSSProperties;
  disabled?: boolean;
}) => {
  const { style, disabled } = props;
  const { mutate, isPending: isSubmitPending } = useRecordMeetingMutation();
  const [inviteLink, setInviteLink] = useState<string>('');
  const submitInviteLink = useCallback(() => {
    mutate({
      meetingUrl: inviteLink,
    });
    setInviteLink('');
  }, [inviteLink]);

  const meetingLinkInput = useId('meeting-link-input');

  return (
    <Card
      style={{
        backgroundColor: tokens.colorSubtleBackgroundHover,
        ...(style ?? {}),
      }}
    >
      <h2 style={{ paddingLeft: 10 }}>
        Invitez le Bot Axys Notes à se joindre à votre réunion
      </h2>
      <div style={{ display: 'flex', alignItems: 'center', flex: 1, gap: 20 }}>
        <div style={{ flex: 1 }}>
          <Label
            htmlFor={meetingLinkInput}
            style={{ color: tokens.colorBrandForeground1 }}
          >
            Adresse d'invitation à la réunion
          </Label>
          <Input
            style={{ display: 'flex', flex: 1 }}
            placeholder="https://teams.microsoft.com/l/meetup-join/..."
            value={inviteLink}
            disabled={disabled || isSubmitPending}
            onChange={(_, newValue) => {
              setInviteLink(newValue.value || '');
            }}
          />
        </div>
        <CompoundButton
          appearance="subtle"
          style={{ width: 200 }}
          icon={<BotAddRegular />}
          disabled={disabled || isSubmitPending || !inviteLink}
          onClick={submitInviteLink}
          secondaryContent="Le bot rejoindra la réunion"
        >
          Rejoindre
        </CompoundButton>
      </div>
    </Card>
  );
};
