import {
  Badge,
  Button,
  Card,
  createTableColumn,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnSizingOptions,
  TableHeader,
  TableHeaderCell,
  TableRow,
  tokens,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from '@fluentui/react-components';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SearchField } from './search-field';
import { useSearchRecordingsQuery } from '../../queries/recording';
import { RecordingsListItem, Status, StepsStatus } from '@axys-notes/common';
import { TableColumnId, TableRowData } from '@fluentui/react-table';
import {
  ArrowLeftFilled,
  ArrowRightFilled,
  DocumentBulletListRegular,
  MicRecordRegular,
  PenSparkleRegular,
} from '@fluentui/react-icons';
import { usePagination } from '../../utils/use-pagination';
import useWindowDimensions from '../../utils/use-window-dimensions';

const columns: TableColumnDefinition<RecordingsListItem>[] = [
  createTableColumn<RecordingsListItem>({
    columnId: 'meeting',
    renderHeaderCell: () => <>Réunion</>,
  }),
  createTableColumn<RecordingsListItem>({
    columnId: 'summary',
    renderHeaderCell: () => <>Sujet</>,
  }),
  createTableColumn<RecordingsListItem>({
    columnId: 'date',
    renderHeaderCell: () => <>Date</>,
  }),
  createTableColumn<RecordingsListItem>({
    columnId: 'duration',
    renderHeaderCell: () => <>Durée</>,
  }),
  createTableColumn<RecordingsListItem>({
    columnId: 'status',
    renderHeaderCell: () => <>Statut</>,
  }),
];

export const RecordingsListPanel = (props: {
  style?: CSSProperties;
  openMeetingReport: (id: string) => void;
}) => {
  const { openMeetingReport, style } = props;
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const { data } = useSearchRecordingsQuery(searchQuery, page);
  const items: RecordingsListItem[] = useMemo(
    () => data?.recordings || [],
    [data]
  );

  const { width: wWidth } = useWindowDimensions();
  const width = useMemo(() => wWidth - 55, [wWidth]);

  const [columnSizingOptions, setColumnSizingOptions] =
    useState<TableColumnSizingOptions>({
      meeting: {
        minWidth: 100,
        defaultWidth: Math.round(width / 5),
      },
      summary: {
        minWidth: 150,
        defaultWidth: Math.round(width / 1.8),
      },
      date: {
        minWidth: 80,
        defaultWidth: 80,
      },
      duration: {
        minWidth: 80,
        defaultWidth: 80,
      },
      status: {
        minWidth: 180,
        defaultWidth: 80,
      },
    });

  const onColumnResize = useCallback(
    (
      _: KeyboardEvent | TouchEvent | MouseEvent | undefined,
      data: {
        columnId: TableColumnId;
        width: number;
      }
    ) => {
      const { columnId, width } = data;
      setColumnSizingOptions((state) => ({
        ...state,
        [columnId]: {
          ...state[columnId],
          idealWidth: width,
        },
      }));
    },
    []
  );

  const { tableRef, columnSizing_unstable, getRows } =
    useTableFeatures<RecordingsListItem>({ columns, items }, [
      useTableColumnSizing_unstable({ columnSizingOptions, onColumnResize }),
    ]);

  const rows: TableRowData<RecordingsListItem>[] = getRows();

  const {
    currentPage,
    totalPages,
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
  } = usePagination(data?.total || 0, 20);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  return (
    <Card
      style={{
        width,
        backgroundColor: tokens.colorSubtleBackgroundHover,
        ...(style ?? {}),
      }}
    >
      <div
        style={{
          marginLeft: 'auto',
        }}
      >
        <SearchField onChange={setSearchQuery} />
      </div>
      <Table ref={tableRef} {...columnSizing_unstable.getTableProps()}>
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell
                key={column.columnId}
                style={{ fontWeight: 'bold' }}
                {...columnSizing_unstable.getTableHeaderCellProps(
                  column.columnId
                )}
              >
                {column.renderHeaderCell()}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.map((item) => (
            <TableRow
              style={{ cursor: 'pointer' }}
              key={item.item.id}
              onClick={() => openMeetingReport(item.item.id)}
            >
              <TableCell
                {...columnSizing_unstable.getTableCellProps('meeting')}
              >
                <TableCellLayout appearance="primary">
                  {item.item.name || '...'}
                </TableCellLayout>
              </TableCell>
              <TableCell
                {...columnSizing_unstable.getTableCellProps('summary')}
              >
                <TableCellLayout>{item.item.summary || '...'}</TableCellLayout>
              </TableCell>
              <TableCell {...columnSizing_unstable.getTableCellProps('date')}>
                <TableCellLayout truncate>
                  {item.item.startTime
                    ? new Date(item.item.startTime).toLocaleDateString()
                    : '...'}
                </TableCellLayout>
              </TableCell>
              <TableCell
                {...columnSizing_unstable.getTableCellProps('duration')}
              >
                <TableCellLayout truncate>
                  {item.item.endTime && item.item.startTime
                    ? `${Math.ceil(
                        (item.item.endTime - item.item.startTime) / (60 * 1000)
                      )} minute(s)`
                    : '...'}
                </TableCellLayout>
              </TableCell>
              <TableCell {...columnSizing_unstable.getTableCellProps('status')}>
                <TableCellLayout truncate={false}>
                  <RecordingStatus status={item.item.status} />
                </TableCellLayout>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {totalPages > 0 && (
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <Button
            icon={<ArrowLeftFilled />}
            onClick={setPreviousPage}
            disabled={!previousEnabled}
          />
          <Button
            icon={<ArrowRightFilled />}
            onClick={setNextPage}
            disabled={!nextEnabled}
          />
          <span>
            Page: {currentPage}/{totalPages}
          </span>
        </div>
      )}
    </Card>
  );
};

export const RecordingStatus = (props: { status: StepsStatus }) => {
  const { status } = props;
  const { text, type } = useMemo<{
    text: string;
    type: keyof StepsStatus;
  }>(() => {
    switch (status.summarizer) {
      case Status.COMPLETED:
        return { text: 'Compte-rendu généré', type: 'summarizer' };
      case Status.ERROR:
        return { text: 'Erreur de compte-rendu', type: 'summarizer' };
      case Status.STARTED:
        return { text: 'Génération du compte-rendu', type: 'summarizer' };
      case Status.PENDING:
      default:
        switch (status.stt) {
          case Status.COMPLETED:
            return { text: 'Transcription terminée', type: 'stt' };
          case Status.ERROR:
            return { text: 'Erreur de transcription', type: 'stt' };
          case Status.STARTED:
            return { text: 'Génération de la transcription', type: 'stt' };
          case Status.PENDING:
          default:
            switch (status.recorder) {
              case Status.COMPLETED:
                return {
                  text: 'Enregistrement terminé',
                  type: 'recorder',
                };
              case Status.ERROR:
                return {
                  text: "Erreur d'enregistrement",
                  type: 'recorder',
                };
              case Status.STARTED:
                return {
                  text: 'Enregistrement en cours',
                  type: 'recorder',
                };
              case Status.PENDING:
              default:
                return { text: 'En attente', type: 'recorder' };
            }
        }
    }
  }, [status]);

  const color = useMemo(() => {
    switch (status[type]) {
      case Status.COMPLETED:
        return 'success';
      case Status.ERROR:
        return 'danger';
      case Status.STARTED:
        return 'brand';
      case Status.PENDING:
        return 'informative';
    }
  }, [status, type]);

  const icon = useMemo(() => {
    switch (type) {
      case 'recorder':
        return <MicRecordRegular />;
      case 'stt':
        return <PenSparkleRegular />;
      case 'summarizer':
        return <DocumentBulletListRegular />;
    }
  }, [type]);

  return (
    <Badge appearance="filled" color={color} iconPosition="before" icon={icon}>
      {text}
    </Badge>
  );
};
