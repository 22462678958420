import { useGetMetadataQuery } from '../../queries/recording';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  tokens,
} from '@fluentui/react-components';
import { CSSProperties } from 'react';

export const StatsPanel = (props: { style?: CSSProperties}) => {
  const { style } = props;
  const { data } = useGetMetadataQuery();
  return (
    <Card
      style={{
        backgroundColor: tokens.colorSubtleBackgroundHover,
        ...style ?? {}
      }}
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }} colSpan={2}>
              Statistiques du service
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>Enregistrements en attente</TableCell>
            <TableCell style={{ textAlign: 'right' }}>
              {data?.service?.recordings.pending ?? '...'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Enregistrements en cours</TableCell>
            <TableCell style={{ textAlign: 'right' }}>
              {data?.service?.recordings.running ?? '...'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};
