import { z } from 'zod';
import { WhisperSegmentSchema, WhisperWordSchema } from './whisper-response';
import { RosterSchema } from './roster';

export const WordSchema = WhisperWordSchema.extend({});

export type Word = z.infer<typeof WordSchema>

export const SegmentSchema = WhisperSegmentSchema.extend({
  speaker: z.string(),
  words: z.array(WordSchema)
});

export type Segment = z.infer<typeof SegmentSchema>;

export const TranscriptSchema = z.object({
  segments: z.array(SegmentSchema),
  roster: RosterSchema
});

export type Transcript = z.infer<typeof TranscriptSchema>;

