import React, { useState } from 'react';
import { NewRecordingPanel } from '../../components/new-recording-panel';
import { RecordingsListPanel } from '../../components/recordings-list-panel';
import { StatsPanel } from '../../components/stats-panel';
import { useGetMetadataQuery } from '../../queries/recording';
import { useIsLargeScreen } from '../../utils/use-is-large-screen';
import { RecordingDrawer } from '../../components/recording-drawer';

export const Home = () => {
  const { data } = useGetMetadataQuery();

  const [displayedRecordingId, setdisplayedRecordingId] = useState<
    string | undefined
  >(undefined);

  const isLargeScreen = useIsLargeScreen();

  return (
    <>
      <div
        style={{
          flex: 1,
          gap: 20,
          padding: 20,
          display: 'grid',
          gridTemplateAreas: isLargeScreen
            ? `
          'stats  .  new  new'
          'list list list list'
         `
            : `
          'stats'
          'new'
          'list'
        `,
          gridTemplateColumns: isLargeScreen ? '1fr 1fr 1fr 1fr' : '1fr',
        }}
      >
        <StatsPanel style={{ gridArea: 'stats' }} />
        <NewRecordingPanel
          style={{ gridArea: 'new' }}
          disabled={data?.user.hasRunningRecording}
        />
        <RecordingsListPanel
          style={{ gridArea: 'list' }}
          openMeetingReport={setdisplayedRecordingId}
        />
      </div>
      {displayedRecordingId && (
        <RecordingDrawer
          recordingId={displayedRecordingId}
          onClose={() => setdisplayedRecordingId(undefined)}
        />
      )}
    </>
  );
};
