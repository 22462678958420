import { Spinner } from '@fluentui/react-components';
import { useGetRecordingQuery } from '../../queries/recording';
import { useParams } from 'react-router-dom';
import { Navbar } from '../../components/navbar';
import { GlobalInformations } from '../../components/recording-global-informations';
import { MeetingNotes } from '../../components/meeting-notes';

export const RecordingView = () => {
  const { recordingId } = useParams();
  const { data, isLoading } = useGetRecordingQuery(recordingId);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: 20,
        padding: 20,
      }}
    >
      <Navbar path={data?.name ? [{ name: data.name }] : []} />

      {isLoading ? (
        <Spinner label="Loading..." size="large" />
      ) : data ? (
        <>
          <GlobalInformations data={data} />
          {data.report && <MeetingNotes report={data.report} />}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

