import { Field, InputOnChangeData } from '@fluentui/react-components';
import { SearchBox, SearchBoxChangeEvent } from '@fluentui/react-search';
import debounce from "lodash.debounce";
import React, { useEffect, useMemo } from 'react';

export const SearchField = (props: {
  onChange: (search: string) => void
}) => {
  const { onChange } = props;
  const debouncedOnChange = useMemo(() => {
    return debounce(onChange, 500);
  }, [onChange]);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  });

  return (
    <Field>
      <SearchBox
        size="small"
        placeholder="Search"
        onChange={(
          _: SearchBoxChangeEvent,
          data: InputOnChangeData
        ) => debouncedOnChange(data.value)}
      />
    </Field>
  )
}
