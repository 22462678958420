import { z } from 'zod';

export enum Status {
  PENDING = 'pending',
  STARTED = 'started',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export const StatusEnum = z.nativeEnum(Status);
export type StatusEnum = z.infer<typeof StatusEnum>


export const StepsStatusSchema = z.object({
  recorder: StatusEnum,
  stt: StatusEnum,
  summarizer: StatusEnum
})

export type StepsStatus = z.infer<typeof StepsStatusSchema>
