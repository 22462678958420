import { useMutation, useQuery } from '@tanstack/react-query';
import { BACKEND_URL } from '../constants';
import {
  Metadata,
  ProcessMeetingJobApi,
  RecordingDTO,
  RecordingsList,
} from '@axys-notes/common';
import { useTeamsContext } from '../providers/teams-context-provider';

export const useRecordMeetingMutation = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useMutation<void, Record<string, unknown>, ProcessMeetingJobApi>({
    mutationKey: ['search'],
    mutationFn: async (query: ProcessMeetingJobApi) => {
      const response = await fetch(`${BACKEND_URL}/api/recording`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
        body: JSON.stringify(query),
      });

      await handleErrorResponse(response, renewAuthCode);
      return;
    },
  });
};

export const useSearchRecordingsQuery = (searchQuery: string, page: number) => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useQuery<RecordingsList>({
    enabled: !!authCode,
    queryKey: ['recording-list-get', searchQuery, page],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 10000,
    queryFn: async () => {
      const response = await fetch(
        `${BACKEND_URL}/api/recording?${new URLSearchParams({
          searchQuery,
          page: String(page),
        })}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authCode ? `Bearer ${authCode}` : '',
          },
        }
      );
      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
  });
};

export const useGetMetadataQuery = () => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useQuery<Metadata>({
    enabled: !!authCode,
    queryKey: ['metadata'],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 10000,
    queryFn: async () => {
      const response = await fetch(`${BACKEND_URL}/api/recording/metadata`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authCode ? `Bearer ${authCode}` : '',
        },
      });
      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
  });
};

export const useGetRecordingQuery = (recordingId?: string) => {
  const { authCode, renewAuthCode } = useTeamsContext();
  return useQuery<RecordingDTO>({
    enabled: !!authCode && !!recordingId,
    queryKey: ['recording-get', recordingId],
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 10000,
    queryFn: async () => {
      const response = await fetch(
        `${BACKEND_URL}/api/recording/${recordingId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authCode ? `Bearer ${authCode}` : '',
          },
        }
      );
      await handleErrorResponse(response, renewAuthCode);
      return response.json();
    },
  });
};

const handleErrorResponse = async (
  response: Response,
  renewAuthCode: (() => void) | undefined
) => {
  if (response.ok) {
    return;
  }

  let message;
  if (response.status === 403) {
    try {
      const json = await response.json();
      if (json['message']?.includes('jwt expired') && renewAuthCode) {
        renewAuthCode();
      }
      message = json.message;
    } catch (e) {
      message = response.statusText;
    }
  } else {
    message = response.statusText;
  }
  throw new Error(message);
};
