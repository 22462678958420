import { z } from 'zod';

export const UserMetadataSchema = z.object({
  hasRunningRecording: z.boolean(),
});

export type UserMetadata = z.infer<typeof UserMetadataSchema>;

export const ServiceMetadataSchema = z.object({
  recordings: z.object({
    pending: z.number(),
    running: z.number(),
  }),
});

export type ServiceMetadata = z.infer<typeof ServiceMetadataSchema>;

export const MetadataSchema = z.object({
  user: UserMetadataSchema,
  service: ServiceMetadataSchema,
});

export type Metadata = z.infer<typeof MetadataSchema>;
