import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';

import App from './app/app';

import "./main.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

microsoftTeams.app
  .initialize()
  .then(() => {
    root.render(
      <StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </StrictMode>
    );
  });
