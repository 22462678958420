import { z } from 'zod';

export const ReportTopicKeyPointSchema = z.object({
  keyPoint: z
    .string()
    .describe(
      "Le nom le plus concis possible pour décrire le point clef d'un sujet, thème ou projet"
    ),
  summary: z.string().describe('Une description détaillée du point clef'),
});

export type ReportTopicKeyPoint = z.infer<typeof ReportTopicKeyPointSchema>;

export const ReportTopicActionSchema = z.object({
  action: z
    .string()
    .describe(
      "Le nom le plus concis possible pour décrire l'action à entreprendre sur un sujet, thème ou projet"
    ),
  summary: z.string().describe("Une description détaillée de l'action"),
});

export type ReportTopicKAction = z.infer<typeof ReportTopicActionSchema>;

export const ReportTopicSchema = z
  .object({
    topic: z
      .string()
      .describe(
        'Le nom le plus concis possible pour décrire le sujet, thème ou projet'
      ),
    summary: z
      .string()
      .describe('Un résumé en quelques lignes du sujet, thème ou projet'),
    keyPoints: z
      .array(ReportTopicKeyPointSchema)
      .describe(
        "Une liste complète et détaillées des points clefs d'un sujet, thème ou projet"
      ),
    actions: z
      .array(ReportTopicActionSchema)
      .describe(
        'Une liste complète et détaillées des actions à mener suite à la discussion sur un sujet, thème ou projet'
      ),
  })
  .describe('Une objet représentant un sujet de la réunion');

export type ReportTopic = z.infer<typeof ReportTopicSchema>;

export const MeetingReportSchema = z.object(
  {
    summary: z.string({
      description:
        "Un résumé en quelques lignes de l'ensemble de la réunion",
    }),
    topics: z.array(ReportTopicSchema, {
      description:
        'Une liste complète et détaillée des sujets qui ont été discutés lors de la réunion.',
    }),
  },
  {
    description: 'Un compte-rendu de réunion structuré',
  }
);

export type MeetingReport = z.infer<typeof MeetingReportSchema>;

export const SummarizeMeetingResultSchema = z.object({
  report: MeetingReportSchema,
});

export type SummarizeMeetingResult = z.infer<
  typeof SummarizeMeetingResultSchema
>;
