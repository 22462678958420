import {
  Button,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  Spinner, useRestoreFocusSource
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import React from 'react';
import { useGetRecordingQuery } from '../../queries/recording';
import { useIsLargeScreen } from '../../utils/use-is-large-screen';
import { GlobalInformations } from '../recording-global-informations';
import { MeetingNotes } from '../meeting-notes';

export const RecordingDrawer = (props: {
  recordingId?: string;
  onClose: () => void
}) => {
  const { recordingId, onClose } = props;
  const { data, isLoading } = useGetRecordingQuery(recordingId);

  const restoreFocusSourceAttributes = useRestoreFocusSource();

  const isLargeScreen = useIsLargeScreen();

  return (
    <OverlayDrawer
      {...restoreFocusSourceAttributes}
      size={isLargeScreen ? "large" : "full"}
      position="end"
      open={!!recordingId}
      onOpenChange={(_, state) => !state.open && onClose()}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={onClose}
            />
          }
        >
          {isLoading || !data ? '' : data.name}
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        {isLoading ? (
          <Spinner label="Loading..." size="large" />
        ) : data ? (
          <div style={{display: 'flex', flexDirection: 'column', gap: 20, padding: "20px 0"}}>
            <GlobalInformations data={data} />
            {data.report && <MeetingNotes report={data.report} />}
          </div>
        ) : (
          <></>
        )}
      </DrawerBody>
    </OverlayDrawer>
  )

}
