import { z } from 'zod';

export const WhisperWordSchema = z.object({
  word: z.string(),
  start: z.number().optional(),
  end: z.number().optional(),
  score: z.number().optional()
});

export type WhisperWord = z.infer<typeof WhisperWordSchema>

export const WhisperSegmentSchema = z.object({
  start: z.number(),
  end: z.number(),
  text: z.string(),
  words: z.array(WhisperWordSchema)
});

export type WhisperSegment = z.infer<typeof WhisperSegmentSchema>;

export const WhisperResponseSchema = z.object({
  segments: z.array(WhisperSegmentSchema)
});

export type WhisperResponse = z.infer<typeof WhisperResponseSchema>;


