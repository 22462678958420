import { RecordingDTO } from '@axys-notes/common';
import {
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  tokens,
} from '@fluentui/react-components';
import { List, ListItem } from '@fluentui/react-list-preview';
import { CrownRegular } from '@fluentui/react-icons';
import React from 'react';
import { RecordingStatus } from '../recordings-list-panel';

export const GlobalInformations = (props: { data: RecordingDTO }) => {
  const { data } = props;
  return (
    <Card
      style={{
        backgroundColor: tokens.colorSubtleBackgroundHover,
      }}
    >
      <h2 style={{ fontWeight: 'bold', paddingLeft: 15 }}>
        Informations générales
      </h2>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ fontWeight: 500, width: '30%' }}>
              Statut
            </TableCell>
            <TableCell style={{ width: '70%' }}>
              <RecordingStatus status={data.status} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>
              Objet de la réunion
            </TableCell>
            <TableCell>
              {data.name || noInfosLabel('...')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>
              Date de la réunion
            </TableCell>
            <TableCell>
              {data.startTime
                ? new Date(data.startTime).toLocaleString()
                : noInfosLabel('...')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>Durée</TableCell>
            <TableCell>
              {data.startTime && data.endTime
                ? `${Math.ceil(
                    (data.endTime - data.startTime) / (60 * 1000)
                  )} minute(s)`
                : noInfosLabel('...')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ fontWeight: 500 }}>Participants</TableCell>
            <TableCell>
              {Array.isArray(data.roster) && data.roster.length ? (
                <List>
                  {data.roster.map((attendee, index) => (
                    <ListItem
                      key={index}
                      style={{
                        margin: '10px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                      }}
                    >
                      <Avatar
                        aria-label={attendee.name}
                        name={attendee.name}
                        badge={
                          attendee.organizer
                            ? { icon: <CrownRegular /> }
                            : undefined
                        }
                      />
                      {attendee.name}
                    </ListItem>
                  ))}
                </List>
              ) : (
                noInfosLabel('...')
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

const noInfosLabel = (message: string) => (
  <span style={{ fontStyle: 'italic' }}>{message}</span>
);
