import { z } from 'zod';
import { StepsStatusSchema } from './status';

export const RecordingsListItemSchema = z.object({
  id: z.string(),
  meetingId: z.string(),
  tenantId: z.string(),
  name: z.string().optional(),
  summary: z.string().optional(),
  startTime: z.number().optional(),
  endTime: z.number().optional(),
  status: StepsStatusSchema
});

export type RecordingsListItem = z.infer<typeof RecordingsListItemSchema>;


export const RecordingsListSchema = z.object({
  total: z.number(),
  recordings: z.array(RecordingsListItemSchema),
});

export type RecordingsList = z.infer<typeof RecordingsListSchema>;
